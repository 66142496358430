import React from "react";
import { styled } from "../../stitches.config";
import truncate from "../../helpers/truncate";

interface ITruncatText {
  text: string;
  maxLength: number;
}

const TruncatText: React.FC<ITruncatText> = ({ text, maxLength }) => {
  const isTruncated = truncate(text ?? "", maxLength).includes("...");

  if (isTruncated) {
    return (
      <>
        {truncate(text ?? "", maxLength)}&nbsp;
        <StyledReadMore>Czytaj dalej</StyledReadMore>
      </>
    );
  }
  return <>{text}</>;
};

const StyledReadMore = styled("span", {
  textDecoration: "underline",
  color: "$primary",
});

export default TruncatText;
