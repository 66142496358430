import React from "react";
import { styled } from "../../stitches.config";

import Link from "../Link";
import KulkiSvg from "./KulkiSvg"

import PlayIcon from "../../assets/img/play.svg";
import Dwukropek from "../../assets/img/natemat/dots_orange.svg";
import CommentImage from "../../assets/img/comment_gray.svg";
import FacebookImage from "../../assets/img/facebook_round_gray.svg";

import Media from "./Media"

const PhotoOnLeft = props => {
  const { article, handleClick = () => { } } = props;
  const imageSize = (props.size == 'lg' && window.innerWidth > 992 ? 246 : 88);
  //5805
  const imageHeight = props.size == 'lg' && window.innerWidth > 992 ? 267 : 110
  const partner = (article.partner ? <Partner>{article.partner}</Partner> : null);
  const label = (article.label ? <Label className="hidden-xs">{article.label}</Label> : null);
  const player = (parseInt(article.player) === 1 ? <Player /> : null);
  const imgUrl = (article.author?.url?.includes("blogi") || !article.picture?.img) ? article.author.img : article.picture.img;

  var stats = [];
  if (article.stats) {
    stats.push(<Likes key='shares'><img src={FacebookImage} alt="" /><span data-facebook-stats> {article.stats.shares}</span></Likes>);
    stats.push(<Likes key='comments'><img src={CommentImage} alt="" /><span data-comments-stats> {article.stats.comments}</span></Likes>);
  }

  return (
    <BoxWrapper onClick={handleClick} hasMobilePcs={props.article.hasMobilePcs} hasDesktopPcs={props.article.hasDesktopPcs} className={('PhotoOnLeft__size--' + props.size) + " " + (article.background ? 'background--' + article.background : null) + " photo-on-left"}  >
      <Link to={article.url}>
        <span>
          <ImageWrapper>
            <Media
              media={props.media}
              src={imgUrl}
              preserveRatio={false}
              aspectRatio={props.size == 'lg' && window.innerWidth > 992 ? 0.92 : 0.8}
              width={imageSize}
              height={imageHeight} />
            {player}
            {partner}
            {label}
          </ImageWrapper>
          {/* <Stats data-stat-furl={article.url} >{stats}</Stats> */}
        </span>
        <Title className="photo-on-left__title" hasMobilePcs={props.article.hasMobilePcs} hasDesktopPcs={props.article.hasDesktopPcs}>
          <KulkiSvg />
          {article.title}
        </Title>
      </Link>
    </BoxWrapper>
  );
};

const Title = styled("span", {
  color: "#272424",
  fontSize: "14.1px",
  padding: "5px 5px 5px 30px",
  display: "block",
  position: "relative",
  overflow: "hidden",
  maxHeight: "100px",
  "> svg": {
    display: "block",
    position: "absolute",
    top: "0",
    left: "10px"
  },
  "@media (min-width: 992px)": {
    color: "#272424",
  },
  variants: {
    "hasMobilePcs": {
      true: {
        color: "#fff",
        "@media (min-width: 992px)": {
          color: "#fff",
          backgroundColor: "transparent"
        }
      },
      false: {}
    }
  }
});

const ImageWrapper = styled("span", {
  display: "block",
  position: "relative",
  marginLeft: "-1px",
  marginTop: "-1px",
  marginBottom: "-1px",
  "img": {
    width: "88px",
    height: "110px"
  }
});

const Partner = styled("span", {
  display: "block",
  position: "absolute",
  left: "0",
  top: "0",
  padding: "0px 8px",
  backgroundColor: "#D8D8D8",
  color: "#8B8B8B",
  lineHeight: "16px",
  fontSize: "11px",
  zIndex: 4
});

const Label = styled("span", {
  display: "block",
  position: "absolute",
  left: "30px",
  top: "30px",
  padding: "0px 10px",
  background: "$primary",
  color: "white",
  lineHeight: "22px",
  fontSize: "15px",
  fontWeight: 500
});

const Player = styled("span", {
  display: "block",
  position: "absolute",
  left: "0",
  top: "0",
  bottom: "0",
  right: "0",
  background: `transparent url(${PlayIcon}) no-repeat center center`,
  backgroundSize: "60px auto",
  zIndex: 5
});

const Stats = styled("span", {
  display: "block",
  whiteSpace: "nowrap"
});

const Likes = styled("span", {
  display: "inline-block",
  padding: "0 0 0 6px",
  color: "#7b7b7b",
  fontSize: "10px",
  "img": {
    width: "12px",
    paddingRight: "2px"
  }
});

const BoxWrapper = styled("div", {
  padding: "8px 0",
  borderRadius: "4px",
  "& > a": {
    color: "#272424",
    border: "1px solid rgba(151,151,151,0.15)",
    backgroundColor: "#ffffff",
    textDecoration: "none",
    display: "flex",
    justifyContent: "start",
    position: "relative",
    /* Kreska pionowa */
    "&:after": {
      display: "block",
      position: "absolute",
      top: "-1px",
      right: "-4px",
      content: "''",
      width: "4px",
      height: "0",
      backgroundColor: "$primary",
      borderTopRightRadius: "4px",
      borderBottomRightRadius: "4px",
      transition: "all 0.2s linear",
      transitionDelay: "0s"
    },
    "&:hover": {
      color: "$primary",
      textDecoration: "none",
      boxShadow: "4px 4px 10px 0px rgba(0,0,0,0.3)",
      cursor: "pointer",
      borderBottom: "1px solid transparent",
      "&:after": { height: "calc(100% + 2px)" },
      circle: { fill: "#000", stroke: "#000" }
    },
    circle: { transition: "all .2s linear" }
  },
  /* Opcja z dużym tekstem, wykorzystywana "dwójka" */
  "@media (min-width: 992px)": {
    padding: "12px 0",
    "& > a": {
      backgroundColor: "#ffffff"
    },
    "&.PhotoOnLeft__size--lg": {
      [`& ${ImageWrapper}`]: {
        "img": {
          width: "246px",
          height: "246px"
        }
      },
      [`& ${Stats}`]: {
        textAlign: "right",
        paddingRight: "20px"
      },
      [`& ${Title}`]: {
        fontSize: "24px !important",
        paddingLeft: "45px",
        maxHeight: "268px",
        svg: {
          width: "20px",
          height: "50px",
          top: "-6px",
          left: "13px"
        }
      }
    }
  },
  /* Na głównej, opcja czarna i czerwona */
  "&.background": {
    "&--red": {
      "> a": {
        backgroundColor: "#ED2024"
      },
      [`& ${Title}`]: {
        color: "white"
      }
    },
    "&--black": {
      "> a": {
        backgroundColor: "black"
      },
      [`& ${Title}`]: {
        color: "white"
      }
    }
  },
  variants: {
    "hasMobilePcs":{
      true: {
        "& > a":{
          backgroundColor: "rgba(1, 1, 1, 0.2)",
          "@media (min-width: 992px)":{
            backgroundColor: "rgba(1, 1, 1, 0.2)"
          }
        },
      },
      false: {}
    }
  }
});

export default PhotoOnLeft;
