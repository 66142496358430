import React, { Fragment } from "react";
import { styled } from "../stitches.config";
import { observable } from "mobx";
import { observer, inject } from "mobx-react";

import PhotoOnLeft from "./common/PhotoOnLeft";
import PhotoOnTop from "./common/PhotoOnTop";

import { withlazyload } from './HOCs/WithLazyLoad'

@withlazyload({
  height: 800,
  once: true,
  offset: 600
})
@inject("CategoryStore", "UIStore")
@observer
export default class PostsList extends React.Component {

  @observable posts = [];
  @observable nextPage = false;
  @observable pending = false;
  @observable hideButton = false;

  componentDidMount() {
    this.handleClick();
    // this.posts = this.props.posts;
    // console.log(this.posts)
  }

  handleClick = ({
    url = `${window.API_HOST}/pwa-category/${this.props.CategoryStore.currentSlug}`
  } = {}) => {
    this.pending = true;
    fetch(url)
      .then(response => {
        if (!response.ok) return Promise.reject();
        return response.json();
      })
      .then(response => {
        this.pending = false;
        if (typeof response.LAST_POST_DATE === "undefined") {
          this.hideButton = true;
        } else {
          this.nextPage = `${window.API_HOST}/pwa-category/${this.props.CategoryStore.currentSlug}?to_date=${encodeURIComponent(response.LAST_POST_DATE)}`;
        }
        this.posts.length !== 0 ? this.posts = [...this.posts, ...response.category.last_posts] : this.posts = response.category.last_posts
        // console.log({ response, nextPage: this.nextPage })
      })
      .catch(() => {
        this.pending = false;
        // console.log("no match found")
      });
  };

  render() {
    const { UIStore, posts } = this.props;

    return (
      <div>
        <Wrapper className={"hp"}>
          <Fragment>
            {posts && posts.length > 0 && (
              <div className="container">
                <Header>{this.props.label}<span>:</span></Header>

                {this.posts.map((article, i) => {
                  return <div key={i}
                    className="col-xs-12 col-sm-6 col-md-3"
                  >
                    {UIStore.isDesktop
                      ? <PhotoOnTop article={article} />
                      : <PhotoOnLeft article={article} />
                    }
                  </div>
                })}
              </div>
            )}
          </Fragment>
        </Wrapper>

        {!this.hideButton && posts && posts.length && (
          <ShowMore disabled={this.pending} onClick={() => this.handleClick({ url: this.nextPage })}>
            Pokaż więcej
          </ShowMore>)}
      </div>
    );
  }
}

const Wrapper = styled("div", {
  /* margin: 20px 10px 10px 10px; */
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between"
});

const Header = styled("div", {
  fontSize: "20px",
  fontWeight: 500,
  margin: "15px 0 10px 0",
  "@media (min-width: 992px)": {
    fontSize: "26px",
    margin: "10px 0 5px 0"
  },
  span: { color: "$primary" }
});

export const ShowMore = styled("button", {
  textAlign: "center",
  display: "block",
  textTransform: "uppercase",
  padding: "12px 20px",
  backgroundColor: "#fff",
  color: "#222222",
  margin: "20px auto",
  border: "2px solid #272424",
  borderRadius: "6px",
  width: "230px",
  position: "relative",
  cursor: "pointer",
  "&:before": {
    content: "",
    display: "block",
    width: "40px",
    height: "25px",
    backgroundColor: "#fff",
    position: "absolute",
    top: "-2px",
    right: "-2px"
  },
  "&:after": {
    content: "",
    display: "block",
    width: "40px",
    height: "25px",
    backgroundColor: "#fff",
    position: "absolute",
    bottom: "-2px",
    left: "-2px"
  }
});